.emergency_call{
    margin: 100px 0;
    width: 100%;
    max-width: 1658px;
}
.emergency_call h1{
    color: var(--secondary-font-color);
    font-family: 'Titillium Web', sans-serif;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0 20px;
    text-align: center;
}
.emergency_call h1 span{
    color: #F63159;
    font-weight: 700;
    text-decoration-line: underline;
}