

.home__intro {
    width: 100%;
    height: calc(100vh - 70px);
    background-color: var(--background-color);
    min-height: 650px;
}

.home-intro__left {
    flex: 1;
}

.home-intro__left h1 {
    color: var(--primary-font-color);
    font-family: 'Titillium Web', sans-serif;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.home-intro__left p {
    color: var(--primary-font-color);
    font-family: 'Titillium Web', sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: justify;
}

.home__intro button {
    background-color: var(--button-color );
    border-radius: 3px;
    border: none;
    color: white;
    font-family: 'Titillium Web', sans-serif;
    cursor: pointer;
}

.home__intro button:hover {
    background-color: #615ea4;
    box-shadow: 1px 1px 10px #fff;

}

.home-intro__left button {
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 1em 1.5em;
}

.home-intro__right {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
}

.home-intro__right img {
    width: 100%;
}

.home-intro__right button {
    margin-top: 44px;
    width: fit-content;
    padding: 0.5em 2em;
}
@media screen and (max-width:700px) {
    .slide_content-positioning {
        flex: 0;
        background-color: red;
    }
    .home__intro {
        height: fit-content;
        padding: 50px 0;
    }
}