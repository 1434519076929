.max-width{
    margin: auto;
    height: inherit;
    max-width: 1200px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 90px;
    height: inherit;
    padding: 0 20px;
}
.home__container{
    margin-top: 70px;
}
/* ------------------------------------------------------------------------------ */
.home__therapy-types {
    padding: 70px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    margin: auto;
    /* max-height: calc(100vh - 70px); */
}
.home__therapy-types img{
    height: auto;
    width: 40%;
}
.home__therapy-types h1{
    color: var(--primary-font-color);
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 50px 0;
}
.home__therapy-types h3{
    color: var(--primary-font-color);
    font-family: 'Titillium Web', sans-serif;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    margin-bottom: 25px;
}
.therapy-types__question{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 7.5px;
}
.therapy-types__question button{
    flex: 1;
    display: flex ;
    min-width: 150px;
    flex-direction: column;
    align-items: center;
    background-color: var(--button-color );
    padding: 0.5em 0;
    border-radius: 3px;
    border: none;
    color: white;
    font-family: 'Titillium Web', sans-serif;
    cursor: pointer;
    font-size: 1rem;
}
.therapy-types__question button:hover{
    background-color: #615ea4;
    box-shadow: 1px 1px 10px #fff;
}
.therapy-types__question button > span:first-of-type{
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
/* ------------------------------------------------------------------------------ */
.home__how-it-works{
    width: 100%;
    padding: 100px 0;
    background-color: var(--background-color);
    
}
.how-it-works__left{
    flex: 1;
}
.how-it-works__right{
    flex: 1;
}
.how-it-works__right img{
    width: 100%;
}
/* ------------------------------------------------------------------------------ */
@media screen and (max-width:700px) {
    .max-width {
        flex-direction: column-reverse;
    }
    .home__container{
        text-align: center;
    }
}