.ssWorksCard__container{
    display: flex;
    align-items: top;
    justify-content: start;
    min-height: 120px;
}
.ssWorksCard__container .order{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
}
.ssWorksCard__container .order .number{
    color: #1F1D55;
    font-family: 'Titillium Web', sans-serif;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border: 2px solid #eaeaea;
    width: 57px;
    height: 57px;
    box-shadow: 1px 1px 10px #EFEFEF;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    margin: 11px;
    border-radius: 5px;
}
.ssWorksCard__container .order .line{
    flex: 1;
    border: 2px dashed #707070;
}
.ssWorksCard__container .card-info h1{
    color: #1F1D55;
    font-family: 'Titillium Web', sans-serif;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
    margin-top: 10px;
}
.ssWorksCard__container .card-info p {
    color: #343A31;
    font-family: 'Titillium Web', sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
}