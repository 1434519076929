@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;600;700&display=swap');

:root{
    --primary-color : #1F1D55;
    --button-color : #504E8F;
    /* --background-color : #E1E0F5; */
    --background-color : #D9D9D9;
    --primary-font-color: #1F1D55;
    --secondary-font-color: #504E8F;
    --primary-font : 'Titillium Web', sans-serif;
}