.about-us__container{
    margin-top: 70px;
}
.about-us__intro {
    padding: 50px 0;
    background-color: var(--background-color);
    min-height: 650px;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
}
.about-us__intro .intro__left{
    flex: 1;
}
.about-us__container h1{
    color: var(--primary-font-color);
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.about-us__container p{
    color: var(--primary-font-color);
    color: #1F1D55;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 250% */
    text-align: justify;
}
.about-us__intro .intro__right{
    flex: 1;
}
.about-us__intro .intro__right img{
    width: 100%;
}

.about-us__our-mission{
    box-sizing: border-box;
    padding: 70px 20px;
    display: flex;
    flex-direction: row;
    max-width: 1200px;
    width: 100%;
    margin: auto;
    gap: 60px;
}

.about-us__our-mission .mission__left{
    flex: 1;
}

.about-us__our-mission .mission__right{
    flex: 2;
}
.about-us__our-mission img{
    width: 100%;
}

@media screen and (max-width:700px) {
    .about-us__our-mission{
        flex-direction: column;
    }
}