.contactUs_page{
    margin-top: 70px;
}

.contactUs_intro {
    width: 100%;
    background-color: var(--background-color);
    height: calc(100vh - 70px);
    min-height: 550px;
    padding: 50px 0;
    box-sizing: border-box;
}
.contactUs_intro_content h1{
    color: var(--primary-font-color);
    font-family: var(--primary-font);
    font-size: 2.2rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.contactUs_intro_content p{
    color: var(--primary-font-color);
    font-family: var(--primary-font);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: justify;
}
.contactUs_page .contactUs_intro_content {
    flex: 1;
    min-width: 300px;
}
.contactUs_page .contactUs_intro_image{
    flex: 2;
}
.contactUs_page .contactUs_intro_image img{
    width: 100%;
}

@media screen and (max-width:700px) {
    .contactUs_intro{
        height: fit-content;
    }
}