.contactUs_form{
    width: 100%;
    background-color: white;
    padding: 2rem;
    box-sizing: border-box;
    border-radius: 5px;
}

.contactUs_form .contactUs_form_input{
    width: 100%;
    border: none;
    border-bottom: 2px solid var(--primary-color);
    margin-bottom: 1.8rem;
    min-height: 30px;
}

.contactUs_form .contactUs_form_input::placeholder{
    color: var(--primary-font-color);
    font-family: var(--primary-font);
    font-size: 1rem;
    text-transform: capitalize;
}
.contactUs_form .contactUs_form_input:focus-visible{
    outline: none;
    border: none;
    border-bottom: 3px solid var(--primary-color);
}

.contactUs_form textarea.contactUs_form_input{
    min-height: 70px;
}

.contactUs_form button{
    border: none;
    background-color: var(--primary-color);
    width: 100%;
    padding: 5px 0;
    color: white;
    font-size: 1rem;
    font-family: var(--primary-font);
    cursor: pointer;
    border-radius: 5px;
}

.contactUs_form button:hover{

}