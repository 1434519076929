.social__bar{
    padding-top: 2%;
    padding-bottom: 2%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap:5px;
    /* min-height: 50px; */

}
.social__bar a{
    flex: 1;
}
.social__icon{
    height: auto;
    max-height: 50px;
    color: #504E8F;
    filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.3));
    transition: all .4s ease;
    -webkit-transition: all .4s ease;
    cursor: pointer;
}
.social__icon:hover{
    filter: drop-shadow(0 0 0.20rem rgb(0 0 0 / 0.3));
}
