.about-split__container{
    margin-top: 70px;
}
.about-split__content {
    padding: 70px 0;
    max-width: 1200px;
    width: 100%;
    margin: auto;
    color: var(--primary-font-color);
    font-family: 'Titillium Web', sans-serif;
}
.about-split__content p{
    font-size: 1.2rem;
}
.about-split__content li{
    font-size: 1.2rem;
}