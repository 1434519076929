.footer {
    background-color: var(--background-color);

}

.footer__slogan {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 70px;
    border-bottom: 1px solid #707070;
    max-width: 1200px;
    margin: auto;
    text-align: center;
}

.footer__slogan img {
    max-height: 100px;
}

.footer__slogan h1 {
    color: #504E8F;
    font-family: 'Titillium Web', sans-serif;
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 1.4em;
}

.footer_info {
    display: flex;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 50px 0;
    justify-content: space-evenly;
    gap: 10px;
    flex-wrap: wrap;
}

.info_colum {
    flex: 1;
    padding: 0 15px;
    font-size: 14px;
    color: #bebebe;
    min-width: 230px;
    line-height: 25px;
}

.info_colum h3 {
    color: var(--primary-font-color);
    font-family: 'Titillium Web', sans-serif;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.info_colum .footer__link-list {
    all: unset;
    list-style: none;
}

.info_colum .footer__link-list li {
    display: flex;
    margin-bottom: 10px;
    color: #343A31;
    font-family: 'Titillium Web', sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.footer_new-therapists button{
    border: 1.5px solid var(--primary-color);
    border-radius: 5px;
    width: 90%;
    background-color: transparent;
    color: var(--primary-font-color);
    font-family: 'Titillium Web', sans-serif;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0.5em 0;
    cursor: pointer;
    margin-top: 3px;
    filter: drop-shadow(2px 3px 2px rgb(0 0 0 / 0.3));
        transition: all .4s ease;
        -webkit-transition: all .4s ease;
    }
    
    .footer_new-therapists button:hover {
        filter: drop-shadow(0 0 0.20rem rgb(0 0 0 / 0.3));
}
.footer_published {
    min-height: 55px;
    text-align: center;
    background-color: var(--background-color);
    color: var(--primary-font-color);
    font-family: 'Titillium Web', sans-serif;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
}